import React, { useState } from "react";
import {
  Button,
  InputAdornment,
  TextField,
  Typography,
  Card,
  Checkbox,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Box,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
} from "@mui/material";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";
import "./index.scss";
import axios from "axios";
import HighlightModal from "../BioBot/HighlightModal";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DownloadIcon from "@mui/icons-material/Download";
import { usePDF, Margin, Resolution, PDF } from "react-to-pdf";
import CircularProgress from "@mui/material/CircularProgress";
import pdf from "../../assets/pdf.png";
import ppt from "../../assets/ppt.png";
import doc from "../../assets/doc.png";
import copy from "../../assets/copyClipboard.svg";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import backArrow from "../../assets/backErrow.svg";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import amritTemplate from "../../assets/ppt-template-amrit.png";
import partexTemplate from "../../assets/ppt-template-partex.png";
import pbTemplate from "../../assets/ppt-template-pb.png";
import guardantTemplate from "../../assets/Guardant.png";
import bioconTemplate from "../../assets/Biocon.png";


const DocumentGenerator = () => {
  const [text, setText] = useState("");
  const [searchResults, setSearchResults] = useState();
  const [output, setOutput] = useState();
  const [selectedMetadata, setSelectedMetadata] = useState([]);
  const token = process.env.REACT_APP_BIO_BOT_TOKEN;
  const [selectAll, setSelectAll] = useState(false);
  const [pdfModal, SetPdfModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [cardLoading, setCardLoading] = useState(false);
  const [outputLoading, setOutputLoading] = useState(false);
  const [multiline, setMultiline] = useState(false);
  const [downloadDropdown, setDownloadDropdown] = useState(null);
  const [openPptModal, setOpenPptModal] = useState(false);
  const navigate = useNavigate();
  const [theraputicArea, setTheraputicArea] = React.useState("Oncology");

  const handleSearch = () => {
    const requestData = { query: text, esIndex: theraputicArea };
    setCardLoading(true);
    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/getChunks`, requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setCardLoading(false);
        setSelectAll(false);

        setSearchResults(response?.data.chunk_list);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleCheckboxChange = (metadata) => {
    if (selectedMetadata.includes(metadata)) {
      setSelectedMetadata((prevSelected) =>
        prevSelected.filter((item) => item !== metadata)
      );
    } else {
      setSelectedMetadata((prevSelected) => [...prevSelected, metadata]);
    }
  };

  const handleSelectAllChange = () => {
    setSelectAll(!selectAll);

    if (!selectAll) {
      const allMetadata = searchResults.map((result) => result.metadata);
      setSelectedMetadata(allMetadata);
    } else {
      setSelectedMetadata([]);
    }
  };

  const handleCardClick = (pdfUrl) => {
    setPdfUrl(pdfUrl);
    SetPdfModal(true);
  };

  const closePdfModal = () => {
    SetPdfModal(false);
  };
  const handleGenerateDocument = () => {
    // const selectedMetadata1 = [];

    // for (const result of searchResults) {
    //   console.log("/////", result);
    //   if (result.selected) {
    //     selectedMetadata1.push({
    //       metadata: result.metadata,
    //     });
    //   }
    // }
    // selectedMetadata1.push({
    //   metadata: selectedMetadata,
    // });
    setOutputLoading(true);
    const selectedMetadata1 = selectedMetadata?.map((metadata) => ({
      metadata,
    }));

    const requestBody = {
      query: text,
      chunk_list: selectedMetadata1,
      esIndex: theraputicArea
    };

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/ansfromChunks`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        setOutputLoading(false);
        setOutput(response?.data?.answer);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const downloadPDF = () => {
    const requestBody = {
      training_result: output,
    };
    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/training_download`,
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
          },
          responseType: "arraybuffer",
        }
      )
      .then(function (response) {
        console.log(response.data);
        const blob = new Blob([response.data], {
          type: "application/pdf",
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Document.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(function (error) {
        console.log(error);
      });
    setDownloadDropdown(null);
  };

  const handleGeneratePPT = () => {
    setOpenPptModal(true);
    setDownloadDropdown(null);
  };

  const generatePPT = (template) => {
    // setOutputLoading(true);
    const selectedMetadata1 = selectedMetadata?.map((metadata) => ({
      metadata,
    }));

    const requestBody = {
      ppt_data: output,
      ppt_template: template,
    };

    axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/generatePPT`, requestBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        responseType: "arraybuffer",
      })
      .then(function (response) {
        // setOutputLoading(false);

        const blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        });

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "Document.pptx";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(function (error) {
        console.log(error);
      });

    setDownloadDropdown(null);
    setOpenPptModal(false);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(output);
    toast.success("Copied !");
  };
  const { toPDF, targetRef } = usePDF({
    filename: "Document.pdf",
    method: "save",
    resolution: Resolution.HIGH,
    page: { margin: Margin.MEDIUM, orientation: "portrait" },
  });

  const OpenDownloadDropdown = (event) => {
    setDownloadDropdown(event.currentTarget);
  };

  const downloadFile = (content, type, filename) => {
    const blob = new Blob([content], { type });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // const downloadPDF = () => {
  //   const pdfContent = output;
  //   downloadFile(pdfContent, "application/pdf", "Document.pdf");
  //   setDownloadDropdown(null);
  // };

  const downloadPPT = () => {
    const pptContent = output;
    downloadFile(
      pptContent,
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "Document.pptx"
    );
  };

  const downloadDOCX = () => {
    const docxContent = output;
    downloadFile(
      docxContent,
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "Document.docx"
    );
    setDownloadDropdown(null);
  };
  const handleChange = (event) => {
    console.log(event.target.value);
    setTheraputicArea(event.target.value);
    // setChatEsIndex(event.target.value);
  };
  return (
    <div className="doc-generator">
      <img
        src={backArrow}
        alt=""
        style={{
          cursor: "pointer",
          marginBottom: "10px",
          paddingTop: "3%",
          paddingRight: "3%",
          width: "80px",
        }}
        onClick={() => navigate(-1)}
      />
      <Box sx={{ maxWidth: 250 }}>
        <FormControl fullWidth>
          <InputLabel>Area of interest</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            defaultValue={theraputicArea}
            input={<OutlinedInput label="Theraputic Area" />}
            value={theraputicArea}
            inputProps={{ "aria-label": "Without label" }}
            onChange={handleChange}
          >
            <MenuItem value={"Oncology"}>Oncology</MenuItem>
            <MenuItem value={"Diabetes"}>Diabetes</MenuItem>
            {/* <MenuItem value={"FDA Labels"}>FDA Labels</MenuItem> */}
            {/* <MenuItem value={"Policy Document"}>Policy Document</MenuItem>
            <MenuItem value={"Schedule of Charge"}>Schedule of Charge</MenuItem> */}
          </Select>
        </FormControl>
      </Box>
      <Modal open={openPptModal} onClose={() => setOpenPptModal(false)}>
        <div className="ppt-template-modal">
          <div className="modal-heading">Select template to download</div>
          <div className="card-wrap">
            <div
              className="template-card"
              onClick={() => generatePPT("Amrit_Template")}
            >
              <img src={amritTemplate} alt="" />
              Amrit Template
            </div>
            <div
              className="template-card"
              onClick={() => generatePPT("Partex_Template")}
            >
              <img src={partexTemplate} alt="" />
              Partex Template
            </div>
            <div
              className="template-card"
              onClick={() => generatePPT("PB_Template")}
            >
              <img src={pbTemplate} alt="" />
              PB Template
            </div>
            {theraputicArea === "Oncology" && <div
              className="template-card"
              onClick={() => generatePPT("Guardant_Template")}
            >
              <img src={guardantTemplate} alt="" />
              Guardant Template
            </div>}
            {/* {theraputicArea === "Diabetes" && <div
              className="template-card"
              onClick={() => generatePPT("Biocon_Template")}
            >
              <img src={bioconTemplate} alt="" />
              Biocon Template
            </div>} */}
          </div>
        </div>
      </Modal>
      <div className="doc-wrapper">

        {pdfModal && (
          <HighlightModal
            pdfUrl={pdfUrl}
            open={pdfModal}
            handleClose={closePdfModal}
          />
        )}
        <h2
          style={{
            color: "rgba(0, 26, 92, 1)",
            margin: "1%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {" "}
          Smart Document Generator
        </h2>

        <div style={{ display: "flex", justifyContent: "space-between" }}>

          <TextField
            className="research-searchbar"
            placeholder="Enter Your Query Here to search relevant reference materials
            "
            variant="outlined"
            multiline={true}
            value={text}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "100px",
              },
            }}
            onKeyDown={(e) => {
              if (!e.shiftKey && e.key === "Enter") {
                e.preventDefault();
                handleSearch();
              } /* else if (e.shiftKey && e.key === "Enter") {
                setMultiline(true);
              } */
            }}
            onChange={(e) => setText(e?.target?.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchSharpIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    variant="contained"
                    onClick={handleSearch}
                    style={{
                      borderRadius: "50px",
                      textTransform: "capitalize",
                    }}
                  >
                    {cardLoading ? (
                      <>
                        Searching... &nbsp;
                        <CircularProgress color="inherit" size={20} />{" "}
                      </>
                    ) : (
                      "Search"
                    )}
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </div>

        {searchResults && (
          <>
            <Typography className="search-result-heading">
              <Checkbox
                onChange={handleSelectAllChange}
                checked={selectAll}
              ></Checkbox>{" "}
              <span className="spantext"> Select All</span>
              <span
                style={{
                  width: "80%",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                Search Results
              </span>
            </Typography>
            <div className="search-result">
              {searchResults?.map((result, index) => (
                <Card
                  key={index}
                  className="cards"
                  style={{
                    ...(index % 2 === 0
                      ? {
                        backgroundColor: "#D1DDFC",
                        // textAlign: "justify",
                        // padding: "0 1vw 0 0",
                      }
                      : {
                        backgroundColor: "#FFFAEB",
                        // textAlign: "justify",
                        // padding: "0 1vw 0 0",
                      }),
                  }}
                >
                  <Checkbox
                    onChange={() => handleCheckboxChange(result?.metadata)}
                    checked={selectedMetadata.includes(result?.metadata)}
                    sx={{ height: "fit-content" }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                    onClick={() =>
                      handleCardClick({
                        docLink: result?.docLink,
                        pageNo: result?.pageNo,
                      })
                    }
                  >
                    <Typography className="page-content">
                      {result?.metadata?.source?.name}
                    </Typography>

                    <Typography>
                      {result?.page_content?.slice(0, 280)}
                      {"...."}
                    </Typography>
                  </div>
                </Card>
              ))}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "2%",
                fontWeight: "bold",
                color: "#687182",
              }}
            >{`Selected ${selectedMetadata.length} of ${searchResults?.length} Results`}</div>
            <div className="generate-document-btn">
              <Button
                variant="contained"
                onClick={handleGenerateDocument}
                disabled={selectedMetadata.length === 0}
                style={{
                  padding: " 0.5% 1%",
                  fontWeight: "bolder",
                  fontSize: "18px",
                }}
                className="summaryBtn"
              >
                {outputLoading ? (
                  <>
                    Generating...
                    <CircularProgress color="inherit" size={20} />
                  </>
                ) : (
                  " Generate Document"
                )}
              </Button>
            </div>
          </>
        )}
        {output && (
          <>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography className="output">Output</Typography>
              <div style={{ display: "flex" }}>
                <Button onClick={handleCopyToClipboard}>
                  <img src={copy} alt="" />
                </Button>
                {/* <IconButton onClick={toPDF} color="primary">
                <DownloadIcon />
              </IconButton> */}

                {/* <Button onClick={toPDF} >
                  <img src={pdf} alt="" />
                </Button>
                <Button onClick={handleGeneratePPT}>
                  {" "}
                  <img src={ppt} alt="" />
                </Button>
                <Button onClick={downloadDOCX}>
                  {" "}
                  <img src={doc} alt="" />
                </Button> */}
                <Button onClick={(e) => OpenDownloadDropdown(e)}>
                  <FileDownloadOutlinedIcon
                    style={{ color: "#0074FC", fontSize: "40px" }}
                  />
                </Button>
                <Menu
                  id="download"
                  anchorEl={downloadDropdown}
                  keepMounted
                  open={Boolean(downloadDropdown)}
                  onClose={() => setDownloadDropdown(null)}
                  sx={{ background: "#00000060" }}
                >
                  <MenuItem
                    onClick={downloadPDF}
                    className="list-item-download"
                  >
                    {" "}
                    <img src={pdf} alt="" />
                    PDF
                  </MenuItem>
                  <MenuItem
                    onClick={downloadDOCX}
                    className="list-item-download"
                  >
                    {" "}
                    <img src={doc} alt="" />
                    DOC
                  </MenuItem>
                  <MenuItem
                    onClick={handleGeneratePPT}
                    className="list-item-download"
                  >
                    {" "}
                    <img src={ppt} alt="" />
                    PPT
                  </MenuItem>
                </Menu>
              </div>
            </div>
            <div className=" output-result">
              <pre
                className="pre-data"
                style={{ textAlign: "left", padding: "1vw" }}
                ref={targetRef}
              >
                {output}
              </pre>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DocumentGenerator;

// import { Button, InputAdornment, TextField, Typography } from "@mui/material";
// import React, { useState } from "react";
// import SearchSharpIcon from "@mui/icons-material/SearchSharp";
// import "./index.scss";
// import axios from "axios";

// const DocumentGenerator = () => {
//   const [text, setText] = useState("");
//   const [searchResults, setSearchResults] = useState(null);

//   const token = process.env.REACT_APP_BIO_BOT_TOKEN;

//   const handleSearch = () => {
//     const requestData = { query: text };

//     axios
//       .post(
//         `${process.env.REACT_APP_BIO_BOT_BACKEND_URL}/getChunks`,
//         requestData,
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//             "Content-Type": "application/json",
//             accept: "application/json",
//           },
//         }
//       )
//       .then(function (response) {
//         setSearchResults(response.data);
//       })
//       .catch(function (error) {
//         console.log(error);
//       });
//   };

//   return (
//     <div className="doc-generator">
//       <div style={{ display: "flex" }}>
//         <TextField
//           className="research-searchbar"
//           placeholder="Search For Your Query"
//           variant="outlined"
//           value={text}
//           onChange={(e) => setText(e.target.value)}
//           InputProps={{
//             startAdornment: (
//               <InputAdornment position="start">
//                 <SearchSharpIcon />
//               </InputAdornment>
//             ),
//             endAdornment: (
//               <InputAdornment position="end">
//                 <Button variant="contained" onClick={handleSearch}>
//                   SEARCH
//                 </Button>
//               </InputAdornment>
//             ),
//           }}
//         />
//       </div>
//       <div>
//         <Typography>Search Results</Typography>
//       </div>
//     </div>
//   );
// };

// export default DocumentGenerator;
