import React, { useState, useEffect } from "react";
import "./index.scss";
import axios from "axios";
import { Box, Button, CircularProgress, IconButton, Modal } from "@mui/material";
import QuizComponent from "./Quiz";
import { useNavigate } from "react-router-dom";
import arrow from "../../assets/Vector.svg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { toast } from "react-toastify";
import RenderPDF from "./RenderPDF";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
const PdfUploader = () => {
  const [showCards, setShowCards] = useState(true);
  const [generateTest, setGenerateTest] = useState(false);

  const [cardData, setCardData] = useState([]);
  const [selectedCardData, setSelectedCardData] = useState(null);
  const [pdfName, setPdfName] = useState();
  const [subject, setSubject] = useState(false);
  const [topic, setTopic] = useState();

  const [questionArray, setQuestionArray] = useState();
  const [loading, setLoading] = useState(false);

  const [showText, setShowText] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        // const response = await axios.get("");
        const card = [
          // "DAPASACH M Tablets",
          // "OMALASOFT Capsule",
          // "PROMINAD Tablets",
          // "DAPASACH MS Tablets",
          // "PRANDIAL MD Tablets",
          // "GLIMCIP Tablets",
          // "PRANDIAL M Tablets",

          // "ARMOTRAZ Tablets",
          // "CALUTIDE Tablets",
          // "CYTOCARB Injection",
          // "DASATRUE Tablets",
          // "GRANICIP Injection",
          // "ONCOGEM Injection",
          // "PACILITAX NAB Injection",
          "Oncology",
          "Diabetes",
        ];

        setCardData(card);
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchData();
  }, []);

  const handleCardClick = async (card) => {
    try {
      console.log("first", card);
      setTopic(card);
      // setLoading(true);
      setSubject(true);
      const diabetes = [
        // "DAPASACH M Tablets",
        // "OMALASOFT Capsule",
        // "PROMINAD Tablets",
        // "DAPASACH MS Tablets",
        // "PRANDIAL MD Tablets",
        // "GLIMCIP Tablets",
        // "PRANDIAL M Tablets",

        // "ARMOTRAZ Tablets",
        // "CALUTIDE Tablets",
        // "CYTOCARB Injection",
        // "DASATRUE Tablets",
        // "GRANICIP Injection",
        // "ONCOGEM Injection",
        // "PACILITAX NAB Injection",

        "Basic knowledge of diabetes and it's care components",
        "Matching Basal Insulins With Patient Needs",
        "Insulin and Insulin Pumps",
        "Latest Advances in the Development of Weekly Insulin Formulations",
        "Impact Study",
        "Sitagliptin",
      ];
      const oncology = [
        // "DAPASACH M Tablets",
        // "OMALASOFT Capsule",
        // "PROMINAD Tablets",
        // "DAPASACH MS Tablets",
        // "PRANDIAL MD Tablets",
        // "GLIMCIP Tablets",
        // "PRANDIAL M Tablets",

        // "ARMOTRAZ Tablets",
        // "CALUTIDE Tablets",
        // "CYTOCARB Injection",
        // "DASATRUE Tablets",
        // "GRANICIP Injection",
        // "ONCOGEM Injection",
        // "PACILITAX NAB Injection",
        "Aggarwal JAMA Oncol 2018 G360 for NSCLC",
        "NILE STUDY",
      ];
      card === "Oncology" ? setCardData(oncology) : setCardData(diabetes);
    } catch (error) {
      console.error("Error fetching text data:", error);
    }
  };
  const handleInnerCardClick = async (card) => {
    try {
      console.log("first", card);
      const cardId = card.id;
      setPdfName(card);
      const requestBody = {
        pdf_name: card,
      };
      setLoading(true);
      const insights = `1. Sodium content is a major concern for customers in all regions. Marketing and sales team should focus on highlighting the low sodium content of the drugs to attract more customers.\n2. Adverse reactions are also a common query in North America. The team should provide detailed information about the side effects and how they can be managed to build trust among customers.\n3. Dosage information is another important query in all regions. The team should provide clear and concise information about the recommended dosage of the drugs to avoid confusion among customers.\n4. Contraindications and indications are also important queries in all regions. The team should provide detailed information about the conditions in which the drugs should not be used and the conditions for which they are recommended.\n5. Clinical trials experience is a query in Asia. The team should provide detailed information about the clinical trials and their results to build trust among customers.\n6. Overdosage symptoms are a query in Europe. The team should provide detailed information about the symptoms of overdosage and how they can be managed to build trust among customers.`;
      let keypoints = [];
      setTimeout(() => { }, 10000);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/trainingPDFkeys`,
          requestBody
        )
        .then(function (response) {
          console.log(response?.data?.keypoints, "keypoint");
          keypoints = response?.data?.keypoints;
          setSelectedCardData(keypoints);
          setLoading(false);
        })
        .catch(function (error) {
          console.log(error);
          toast.error("Something went wrong. Please try again !!!");
        });
      const insightsArray = insights
        .split("\n")
        .filter((insight) => insight.trim() !== "");
      console.log(insightsArray);
      setShowCards(false);
      setShowText(true);
    } catch (error) {
      console.error("Error fetching text data:", error);
    }
  };
  const handleStartTestClick = () => {
    const requestBody = {
      pdf_name: pdfName,
    };
    setGenerateTest(true);
    // `${process.env.REACT_APP_BACKEND_URL}/trainingPDFquestions`,

    axios
      .post(
        `${process.env.REACT_APP_BACKEND_URL}/trainingPDFquestions`,
        requestBody
      )
      .then(function (response) {
        console.log(response, "pdfName");
        setQuestionArray(response?.data);
        setShowText(false);
        setGenerateTest(false);
      })
      .catch(function (error) {
        console.log(error);
        toast("Something went wrong. Please try again !!!");
      });
  };

  const handleQuery = () => {
    const url = "https://demo.salesbot.perpetualblock.io/bioBot";
    // const url = "http://localhost:3000/bioBot";

    window.open(url, "_blank");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const pdfFiles = [
    { filename: "Aggarwal JAMA Oncol 2018 G360 for NSCLC.pdf", name: "PDF 1" },
    { filename: "NILE.pdf", name: "PDF 2" }
  ];

  return (
    <div className="fdaDraft">
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{
          ...style, display: "flex", flexDirection: "column", justifyContent: "space-around",
          width: "85%",
          height: "85%",
          margin: "auto",
        }}>
          <IconButton
            sx={{ position: "absolute", top: 0, left: 0 }}
            onClick={handleClose}
            aria-label="close"
          >
            <ArrowBackIosIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-around",
              width: "100%",
              height: "calc(100% - 48px)", // Adjust height to accommodate the icon button
              overflow: "auto", // Add overflow to handle scrolling if needed
            }}
          >
            {pdfFiles.map((pdfFile, index) => (
              <RenderPDF key={index} file={pdfFile} />
            ))}
          </Box>
        </Box>
      </Modal>

      {
        showCards ? (
          <>
            <img
              alt=""
              src={arrow}
              className="arrow-btn"
              onClick={() => navigate(-1)}
            ></img>{" "}
            {subject === false ? (
              <>
                <div className="container">
                  <div className="title">Improve Your Skills</div>
                  <div className="prompt">
                    Explore our curated training modules for skill enhancement.
                  </div>
                </div>
                <div className="cards-section">
                  {cardData?.map((card) => (
                    <div
                      key={card.id}
                      className="card"
                      onClick={() => handleCardClick(card)}
                    >
                      <div>{card}</div>
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <>
                {" "}
                <h1 className="title">Choose a topic of your interest :</h1>
                {topic === "Diabetes" ? (
                  <div className="cards-section">
                    {cardData?.map((card) => (
                      <div
                        key={card.id}
                        className="card"
                        onClick={() => handleInnerCardClick(card)}
                      >
                        <div>{card}</div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="cards-section">
                    {cardData?.map((card) => (
                      <div
                        key={card.id}
                        className="card"
                        onClick={() => handleInnerCardClick(card)}
                      >
                        <div>{card}</div>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </>
        ) : showText ? (
          <>
            <img
              alt=""
              src={arrow}
              className="arrow-btn"
              onClick={() => navigate(-1)}
            ></img>
            <div className="text-section">
              <div className="insights-container">
                <h2 style={{ padding: "1%" }}>Key Points </h2>

                {loading ? (
                  <>
                    {" "}
                    <div className="card-inner-wrapper">
                      <Card>
                        <CardContent className="card-content">
                          <div className="spinner">
                            <h3>Generating KeyPoints </h3>
                            <div className="bounce1"></div>
                            <div className="bounce2"></div>
                            <div className="bounce3"></div>
                          </div>
                        </CardContent>
                      </Card>
                    </div>
                  </>
                ) : (
                  <>
                    {selectedCardData?.map((insight, index) => (
                      <div key={index} className="insight-card">
                        {insight}
                      </div>
                    ))}
                  </>
                )}
              </div>
              <div className="button-container">
                <Button
                  variant="contained"
                  style={{ cursor: "pointer" }}
                  onClick={handleOpen}
                >
                  Training Material
                </Button>
                <Button
                  variant="contained"
                  className="start-test-btn"
                  onClick={handleQuery}
                >
                  Do You Have Any More Queries?
                </Button>
                <Button
                  variant="contained"
                  className="start-test-btn"
                  onClick={handleStartTestClick}
                >
                  {generateTest ? (
                    <>
                      Preparing Test... &nbsp;
                      <CircularProgress color="inherit" size={20} />{" "}
                    </>
                  ) : (
                    "Take A Test "
                  )}
                </Button>
              </div>
            </div>
          </>
        ) : (
          <>{questionArray && <QuizComponent quiz={questionArray} />}</>
        )
      }
    </div >
  );
};

export default PdfUploader;

// import React, { useState } from "react";
// import "./index.scss";
// import arrow from "../../assets/Vector.svg";
// import { DropzoneArea } from "material-ui-dropzone";

// import axios from "axios";
// import { usePDF } from "react-to-pdf";
// import { useNavigate } from "react-router-dom";
// import PdfViewer from "./PdfPreview";
// import QuizComponent from "./Quiz";
// import { Button } from "@mui/material";

// const PdfUploader = () => {
//   const navigate = useNavigate();

//   const [showHeading, setShowHeading] = useState(true);
//   const [resp, setResp] = useState();
//   const [showGif, setShowGif] = useState(true);
//   const [files, setFiles] = useState([]);
//   const [showQuestions, setShowQuestions] = useState(false);
//   const token =
//     "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoiVVNFUiJ9.yW_NP3RB0a1fG-O7C5_5H5tdwSqkW70o5Zc9FmeQWsI";
//   const url = "https://fda.perpetualblock.io/";

//   const handleFileUpload = async (files) => {
//     console.log("uploading", files);
//     setFiles(files);
//     // const formData = new FormData();
//     // formData.append("file", files[0]);

//     setShowHeading(false);
//     // setShowQuestions(true);
//   };
//   const createTraining = async (files) => {
//     setShowQuestions(true);
//   };

//   const { toPDF, targetRef } = usePDF({ filename: "download.pdf" });
//   const yourQuestionsArray = [
//     {
//       questions: [
//         {
//           choices: [
//             "To provide healthcare professionals with information about potential side effects of lenalidomide",
//             "To ensure unborn babies are not exposed to lenalidomide",
//             "To limit the duration of lenalidomide treatment for all patients",
//             "To promote the use of lenalidomide for all patients",
//           ],
//           correct_choice:
//             "To ensure unborn babies are not exposed to lenalidomide",
//           question:
//             "What is the purpose of the Lenalidomide Pregnancy Prevention Program (PPP)?",
//           question_no: "1",
//         },
//         {
//           choices: ["4 weeks", "8 weeks", "12 weeks", "16 weeks"],
//           correct_choice: "4 weeks",
//           question:
//             "What is the maximum prescription length for women of childbearing potential under the lenalidomide PPP?",
//           question_no: "2",
//         },
//         {
//           choices: [
//             "Pressing on both ends of the capsule",
//             "Using bare hands to remove the capsules from the blister",
//             "Wearing disposable gloves when handling the capsules",
//             "Throwing the capsules directly into the trash",
//           ],
//           correct_choice:
//             "Wearing disposable gloves when handling the capsules",
//           question:
//             "What is the preferred method for healthcare professionals and caregivers to handle lenalidomide capsules?",
//           question_no: "3",
//         },
//         {
//           choices: [
//             "Reuse the damaged capsules",
//             "Close the outer carton and return to the pharmacy",
//             "Open the damaged capsules for inspection",
//             "Dispose of the unused packs in the regular trash",
//           ],
//           correct_choice: "Close the outer carton and return to the pharmacy",
//           question:
//             "What is the recommended action if lenalidomide capsules are found to be damaged or leaking?",
//           question_no: "4",
//         },
//         {
//           choices: [
//             "Prescribers only",
//             "Pharmacists only",
//             "Both prescribers and pharmacists",
//             "Nurses only",
//           ],
//           correct_choice: "Both prescribers and pharmacists",
//           question:
//             "What healthcare professional is responsible for reading and understanding the contents of the Lenalidomide Healthcare Professional's Information Pack?",
//           question_no: "5",
//         },
//         {
//           choices: [
//             "Donate blood",
//             "Donate semen",
//             "Use condoms",
//             "Stop all medications",
//           ],
//           correct_choice: "Use condoms",
//           question:
//             "What must male patients do during the treatment and for at least seven days after cessation of lenalidomide?",
//           question_no: "6",
//         },
//         {
//           choices: [
//             "To confirm the patient's insurance information",
//             "To track the patient's prescription history",
//             "To verify the completion of Pregnancy Prevention Program measures",
//             "To collect patient contact information",
//           ],
//           correct_choice:
//             "To verify the completion of Pregnancy Prevention Program measures",
//           question:
//             "What is the primary purpose of the electronic Prescription Authorisation Form (ePAF) for lenalidomide prescriptions?",
//           question_no: "7",
//         },
//         {
//           choices: [
//             "Increased risk of blood clots",
//             "Increased risk of infection",
//             "Increased risk of allergies",
//             "Increased risk of heart disease",
//           ],
//           correct_choice: "Increased risk of blood clots",
//           question:
//             "What is the risk associated with the combination of lenalidomide/dexamethasone in patients with multiple myeloma?",
//           question_no: "8",
//         },
//         {
//           choices: [
//             "Continue the treatment as prescribed",
//             "Stop treatment and start anticoagulation therapy",
//             "Ignore the symptoms and continue normal activities",
//             "Switch to a higher dosage of lenalidomide",
//           ],
//           correct_choice: "Stop treatment and start anticoagulation therapy",
//           question:
//             "What is the recommended action if a patient experiences any thromboembolic events while on lenalidomide treatment?",
//           question_no: "9",
//         },
//         {
//           choices: [
//             "Registration with the TGA",
//             "Agreement to implement lenalidomide counseling program",
//             "Participation in regular audits by Cipla",
//             "Registration with Cipla's Pregnancy Prevention Program",
//           ],
//           correct_choice:
//             "Registration with Cipla's Pregnancy Prevention Program",
//           question:
//             "What is the primary requirement for pharmacies wishing to dispense lenalidomide?",
//           question_no: "10",
//         },
//       ],
//     },
//   ];

//   return (
//     <div className="fdaDraft">
//       {showHeading ? (
//         <>
//           <img
//             alt=""
//             src={arrow}
//             className="arrow-btn"
//             onClick={() => navigate(-1)}
//           ></img>{" "}
//           <div className="heading">
//             <div className="heading-title">Create Training </div>
//             <div className="heading-summary">
//               Generative AI drafter using templates and legal knowledge to
//               create FDA notice responses
//             </div>
//             <div className="heading-support">Supportable file : PDF</div>
//           </div>
//           <div className="upload-section">
//             <div className="upload-section-dotted">
//               <div>
//                 <DropzoneArea
//                   showPreviews={false}
//                   showPreviewsInDropzone={false}
//                   previewGridProps={{
//                     container: { spacing: 1, direction: "row" },
//                   }}
//                   showAlerts={false}
//                   acceptedFiles={[
//                     "application/pdf",
//                     "application/vnd.google-apps.document",
//                     "application/vnd.google-apps.spreadsheet",
//                     "application/msword",
//                   ]}
//                   dropzoneText={
//                     <div className="insideDropzone">
//                       <span style={{ fontSize: "1vw", fontWeight: "bold" }}>
//                         Drag and Drop File here or
//                       </span>
//                       <Button
//                         style={{
//                           alignSelf: "center",
//                           width: "10vw",
//                           fontSize: "1vw",
//                           marginTop: "1vw",
//                           height: "2.2vw",
//                           color: "#153359",
//                           borderColor: "#153359",
//                           borderWidth: "2px",
//                           borderRadius: "10px",
//                         }}
//                       >
//                         Browse File
//                       </Button>
//                     </div>
//                   }
//                   onDrop={(files) => handleFileUpload(files)}
//                   filesLimit={1}
//                   maxFileSize={200 * 1024 * 1024}
//                 />
//               </div>
//             </div>
//           </div>
//         </>
//       ) : showQuestions ? (
//         // Code for rendering questions
//         <QuizComponent quiz={yourQuestionsArray} />
//       ) : (
//         <>
//           <div className="pdf-section">
//             <div className="pdf-heading">Preview of Uploaded File :</div>
//             <div className="pdf-viewer">
//               {console.log("pdfviewer", files)}
//               <PdfViewer files={files} />
//             </div>
//           </div>
//           <div className="btn-div">
//             <Button
//               variant="contained"
//               className="create-btn"
//               style={{ fontSize: "16px", fontWeight: "bold" }}
//               onClick={createTraining}
//             >
//               Create Training
//             </Button>
//           </div>
//         </>
//       )}
//     </div>
//   );
// };

// export default PdfUploader;
