import React, { useEffect, useState } from "react";

function RenderPDF({ file }) {
    // const [file, setFile] = useState(null);

    // useEffect(() => {
    //     const selectedFile = files[0];
    //     setFile(selectedFile);
    // }, [files]);
    console.log(file);
    return (
        <div style={{ width: "45%", height: "100%" }}>
            <object
                data={require(`./${file.filename}`)}
                type="application/pdf"
                width="100%"
                height="100%"
                hideToolbar
            >
                <p>
                    Your browser does not support embedded PDF files. You can download
                    the PDF{" "}
                    <a href={require(`./${file.filename}`)} target="_blank" rel="noopener noreferrer">
                        here
                    </a>
                    .
                </p>
            </object>
        </div>

    );
}

export default RenderPDF;
